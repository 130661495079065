import * as React from "react"
import { Link } from "gatsby"

const NotFoundPage = () => {
  return (
    <main>
      <title>Stránka nenalezena</title>
      <h1>Stránka nenalezena</h1>
      <p>
        <Link to="/">Na hlavní stranu</Link>
      </p>
    </main>
  );
}

export default NotFoundPage
